<!-- eslint-disable vue/multi-word-component-names -->
<template>

    <div :class="containerClass">
        <div class="layout header">
            <a href="/" class="logo">
                <img src="../assets/icon/logo1.png" class="logo-img" alt="">
            </a>
            <div class="notice">
                还没有账号，
                <router-link to="/register">去注册</router-link>
            </div>
        </div>
        <main class="page">
            <div class="layout content">
                <div class="img-box">
                    <img src="../assets/svg/works.svg" class="img" alt="">
                </div>
                <div class="card">
                    <div class="tab-box hide">
                        <div :class="(tab==1 ? 'active' : '') +' item'" @click="tab=1">账号登录</div>
                        <div :class="(tab==2 ? 'active' : '') +' item'" @click="tab=2">短信登录</div>
                    </div>
                    <el-form v-if="tab==1" :model="accountData" :rules="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="账号" prop="account">
                            <el-input v-model="accountData.account"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input type="password" v-model="accountData.password"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="checked">
                            <el-checkbox v-model="checked">登录即视为您已阅读并同意<router-link to="/agreement?key=users">注册协议</router-link>、<router-link to="/agreement?key=privacy">隐私政策</router-link></el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="button" class="loginBtn" @click="accountLogin">立即登录</el-button>
                        </el-form-item>
                          <a href="" class="notice">忘记密码</a>
                    </el-form>

                    <el-form v-if="tab==2" :model="codeData" :rules="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="手机号" prop="mobile">
                            <el-input v-model="codeData.mobile"></el-input>
                        </el-form-item>
                        <el-form-item label="验证码" prop="captcha">
                            <el-input v-model="codeData.captcha">
                                <el-button slot="append" :disabled="isDisabled" @click="sendSms()">{{tip}}</el-button>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="" prop="checked">
                            <el-checkbox v-model="checked">登录即视为您已阅读并同意<router-link to="/agreement?key=users">注册协议</router-link>、<router-link to="/agreement?key=privacy">隐私政策</router-link></el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="loginBtn" @click="codeLogin">立即登录</el-button>
                        </el-form-item>
                        <a href="" class="notice">忘记密码</a>
                    </el-form>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import Api from "@/Api.js";
import { mapActions, mapState } from 'vuex';
export default {
    data() {
        return {
            tip: '获取验证码',
            second: 30,
            isDisabled: false,
            timer: null,
            tab: 1,
            checked: true,
            accountData: {
                account: '',
                password: '',
            },
            codeData: {
                mobile: '',
                captcha: '',
            },
            ruleForm: {
                account: [{
                    required: true,
                    message: '请输入手机号或用户名',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: '请输入密码',
                    trigger: 'blur'
                }],
                mobile: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur'
                }],
                captcha: [{
                    required: true,
                    message: '请输入验证码',
                    trigger: 'blur'
                }],
            },
        }
    },
    methods: {
        ...mapActions(['setToken']),
        // 账号密码登录
        accountLogin() {
            let that = this;
            Api.User.AccountLogin(
                that.accountData,
            ).then((res) => {
                if(res.data.code == 1){
                    that.$store.commit("setToken", res.data.data.userinfo.token);
                    that.$store.commit("setUserInfo",res.data.data.userinfo);
                    that.accountData.account = "";
                    that.accountData.password = "";
                    axios.defaults.withCredentials = true;
                    that.$router.push({path: '/'});
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },
        //短信验证码登录
        codeLogin() {
            let that = this;
            Api.User.MobileLogin(
                that.codeData,
            ).then((res) => {
                if(res.data.code == 1){
                    that.$store.commit("setToken", res.data.data.userinfo.token);
                    that.accountData.account = "";
                    that.accountData.password = "";
                     axios.defaults.withCredentials = true;
                    that.$router.push({path: '/'});
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },
        //获取验证码
        sendSms() {
            let that = this;
            if(!that.codeData.mobile){
                that.$message.error('手机号不能为空');
                return false;
            }
            Api.Config.SendSms({
                mobile: that.codeData.mobile,
                event: 'mobilelogin',
            }).then((res) => {
                if(res.data.code == 1){
                    //按钮倒计时
                    that.btnTimmer();
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },
        //倒计时
        btnTimmer(){
            this.timer = setInterval(() => {
                if(this.second == 0){
                    clearInterval(this.timer);
                    this.tip = '获取验证码';
                    this.isDisabled = false;
                    this.second = 30;
                }else{
                    this.second -- ;
                    this.tip = this.second + 's重新获取';
                    this.isDisabled = true;
                }
            }, 1000);
        },
        containerClass() {
        return window.innerWidth >= 768 ? 'container-row' : 'container-column';
      },
    },
    computed:{
        ...mapState(['token']),
    },
    mounted(){

    }
}
</script>
 
<style scoped>
/* scoped 让style样式 局部生效*/

.header {
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  .container-row {
    display: flex;
    flex-direction: row;
  }

  .container-column {
    display: flex;
    flex-direction: column;
  }
    .search-box{
        display: flex;
        align-items: center;
    }
  .logo-img{
    width: 200px;
    height: 100px;
  }

    .notice{
        color: #333333;

        a{
            color: #D11A2D;
        }
    }

}

.container-row {
  display: flex;
  flex-direction: row;
}

.container-column {
  display: flex;
  flex-direction: column;
}
.search-box{
  display: flex;
  align-items: center;
}
.logo-img{
  width: 200px;
  height: 100px;
}

.notice{
  color: #333333;

  a{
    color: #D11A2D;
  }
}

.page{
    min-height: calc(100vh - 300px);
    background: #f7f8fa;
    display: flex;
    align-items: center;

    .content{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .img-box .img{
            width: 420px;
            height: auto;
        }

        .card{
            margin-left: 80px;
            background: #ffffff;
            border-radius: 10px;
            width: 500px;
            height: 400px;
            padding: 20px 30px 30px 0;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 15px;
            border-top: 5px solid #D11A2D;
        }

        .tab-box{
            padding-left: 100px;
            display: flex;
            align-items: center;
            margin-bottom: 25px;
        }

        .tab-box .item{
            margin-right: 50px;
            height: 40px;
            line-height: 40px;
            cursor: pointer;
            border-bottom: 2px solid transparent;
        }

        .tab-box .item.active{
            color: #D11A2D;
            border-bottom: 2px solid #D11A2D;
        }


        .loginBtn{
            width: 100%;
            background: #D11A2D;
            border-color: #D11A2D;
            color: #ffffff;
        }

        .loginBtn:active{
            background: #e01e31;
            border-color: #e01e31;
        }

        .notice{
            font-size: 14px;
            display: inline-block;
            width: 100%;
            text-align: right;
            color: #999999;
        }
    }
}
.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-box .img{
  width: 420px;
  height: auto;
}

.card{
  margin-left: 80px;
  background: #ffffff;
  border-radius: 10px;
  width: 500px;
  height: 400px;
  padding: 20px 30px 30px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 15px;
  border-top: 5px solid #D11A2D;
}

.tab-box{
  padding-left: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.tab-box .item{
  margin-right: 50px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tab-box .item.active{
  color: #D11A2D;
  border-bottom: 2px solid #D11A2D;
}


.loginBtn{
  width: 100%;
  background: #D11A2D;
  border-color: #D11A2D;
  color: #ffffff;
}

.loginBtn:active{
  background: #e01e31;
  border-color: #e01e31;
}

.notice{
  font-size: 14px;
  display: inline-block;
  width: 100%;
  text-align: right;
  color: #999999;
}
</style>