<!-- eslint-disable vue/multi-word-component-names -->
<template>

    <div>
        <div class="layout header">
            <a href="/" class="logo">
                <img src="../assets/icon/logo1.png" class="logo-img" alt="">
            </a>
            <div class="notice">
                已有账号，
                <router-link to="/login">去登录</router-link>
            </div>
        </div>
        <main class="page">
            <div class="layout content">
                <div class="img-box">
                    <img src="../assets/svg/register.svg" class="img" alt="">
                </div>
                <div class="card">
                    <el-form :model="formData" :rules="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="邀请码" prop="invite_num">
                         <el-input v-model="formData.invite_num" @blur="checkInput()" @input="checkInviteCode"></el-input>
                       </el-form-item>
                        <el-form-item label="手机号" prop="mobile">
                            <el-input v-model="formData.mobile" :disabled="!isInviteCodeFilled"></el-input>
                        </el-form-item>
                        <el-form-item label="验证码" prop="code">
                            <el-input v-model="formData.code">
                                <el-button slot="append" :disabled="isDisabled"  @click="sendSms()">{{tip}}</el-button>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="地区" prop="area_text">
                            <el-cascader :options="areaOptions" :disabled="!isInviteCodeFilled" v-model="formData.area_text" clearable></el-cascader>
                        </el-form-item>
                        <el-form-item label="用户名" prop="username">
                            <el-input v-model="formData.username" :disabled="!isInviteCodeFilled"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input type="password" v-model="formData.password" :disabled="!isInviteCodeFilled"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="checked">
                            <el-checkbox v-model="checked">登录即视为您已阅读并同意<router-link to="/agreement?key=users">注册协议</router-link>、<router-link to="/agreement?key=privacy">隐私政策</router-link></el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="button" class="loginBtn" @click="onSubmit">注册</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import Api from "@/Api.js";
import { mapActions, mapState } from 'vuex';
export default {
    data() {
        return {
            tip: '获取验证码',
            second: 30,
            isDisabled: false,
            timer: null,
            checked: true,
            areaOptions:[],
          isInviteCodeFilled: false, // 新增标识变量
            formData: {
                mobile: '',
                username: '',
                invite_num: '',
                password: '',
                code: '',
                area_text:[],
            },
            ruleForm: {
                mobile: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur'
                }],
                username: [{
                    required: true,
                    message: '请输入用户名',
                    trigger: 'blur'
                }],
                invite_num: [{
                    required: true,
                    message: '请输入邀请码',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: '请输入密码',
                    trigger: 'blur'
                }],
                code: [{
                    required: true,
                    message: '请输入验证码',
                    trigger: 'blur'
                }],
                area_text: [{
                    required: true,
                    message: '请输入地区',
                    trigger: 'blur'
                }],
            },
        }
    },
    created(){
        this.getAreaOptions();
        this.formData.invite_num = this.$route.query.invite_code;
    },
    methods: {
        ...mapActions(['setToken']),
      checkInput(){
        if (!this.formData.invite_num.trim()) {
          this.$message.warning('邀请码不能为空');
          // 可以选择性地重置或清空输入
          // this.formData.invite_num = '';
          this.isInviteCodeFilled=false;
        }else {
          Api.Invitationcode.init({
            code:this.formData.invite_num
          }).then(res=>{
            if (res.data.data.total<=0){
              this.$message({
                showClose: true,
                message: '请输入正确的邀请码',
                type: 'warning'
              });
              this.isInviteCodeFilled=false;
            }else {
              this.$message({
                showClose: true,
                message: 'ok',
                type: 'success'
              });
              this.isInviteCodeFilled=true;
            }
          })
        }

      },
      checkInviteCode() {
        this.isInviteCodeFilled = this.formData.invite_num.trim() !== ''; // 根据邀请码是否为空字符串来更新标识
        // 可以在此处添加更多逻辑，比如长度检查等
      },
        //获取地址地区
        getAreaOptions(){
            let that = this;
            Api.Config.AreaOptions().then((res) => {
                if(res.data.code == 1){
                    that.areaOptions = res.data.data;
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

        // 确认注册
        onSubmit() {
            let that = this;
            if(!that.formData.area_text){
                that.$message.error('请选择所在地');
                return false;
            }
            let area = that.formData.area_text.join('-');
            that.formData.area_text = area;
            Api.User.Register(
                that.formData,
            ).then((res) => {
                if(res.data.code == 1){
                    that.$store.commit("setToken", res.data.data.userinfo.token);
                    that.formData.mobile = "";
                    that.formData.username = "";
                    that.formData.invite_num = "";
                    that.formData.code = "";
                    that.formData.password = "";
                    that.formData.area_text = [];
                    that.$router.push({path: '/'});
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

        //获取验证码
        sendSms() {
            let that = this;
            if(!that.formData.mobile){
                that.$message.error('手机号不能为空');
                return false;
            }
            Api.Config.SendSms({
                mobile: that.formData.mobile,
                event: 'register',
            }).then((res) => {
                if(res.data.code == 1){
                    //按钮倒计时
                    that.btnTimmer();
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

        //倒计时
        btnTimmer(){
            this.timer = setInterval(() => {
                if(this.second == 0){
                    clearInterval(this.timer);
                    this.tip = '获取验证码';
                    this.isDisabled = false;
                    this.second = 30;
                }else{
                    this.second -- ;
                    this.tip = this.second + 's重新获取';
                    this.isDisabled = true;
                }
            }, 1000);
        },
    },
    computed:{
        ...mapState(['token']),
    },
}
</script>
 
<style scoped>
/* scoped 让style样式 局部生效*/
.header {
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-box{
        display: flex;
        align-items: center;
    }

  .logo-img{
    width: 200px;
    height: 100px;
  }

    .notice{
        color: #333333;

        a{
            color: #D11A2D;
        }
    }

}

.page{
    min-height: calc(100vh - 240px);
    background: #f7f8fa;
    display: flex;
    align-items: center;

    .content{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .img-box .img{
            width: 420px;
            height: auto;
        }

        .card{
            margin-left: 80px;
            background: #ffffff;
            border-radius: 10px;
            width: 500px;
            height: 500px;
            padding: 20px 30px 30px 0;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 15px;
            border-top: 5px solid #D11A2D;
        }

        .loginBtn{
            width: 100%;
            background: #D11A2D;
            border-color: #D11A2D;
            color: #ffffff;
        }

        .loginBtn:active{
            background: #e01e31;
            border-color: #e01e31;
        }

        .notice{
            font-size: 14px;
            display: inline-block;
            width: 100%;
            text-align: right;
            color: #999999;
        }
        
        :deep(.el-form-item__content){
            line-height: 0!important;
        }

        :deep(.el-form-item){
            margin-bottom: 20px;
        }

        :deep(.el-cascader){
            width: 100%;
        }
    }
}
</style>