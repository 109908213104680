<!-- eslint-disable vue/multi-word-component-names -->
<template>
   <div class="layout-navs">
        <div class="layout navs-box">
            <router-link class="item active" to="/">首页</router-link>
            <a class="item" target="_blank" href="http://wx.zhongguojiaoyunet.com">网课</a>
            <a class="item" target="_blank" href="https://gaokao.chsi.com.cn/zyck/zy/">志愿填报</a>
            <router-link class="item" to="#">点播课程</router-link>
            <router-link class="item" to="/schools">院校信息</router-link>
            <router-link class="item" to="/occupationallevelIndex">职业资格</router-link>
            <router-link class="item" to="/about">关于我们</router-link>
        </div>
   </div>
</template>
<script>
import Api from "@/Api.js";
export default {
    data() {
        return {
           
        }
    },
    methods: {
        
    },
}
</script>
 
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.layout-navs {
    width: 100%;
    height: 50px;
    position: relative;
    z-index: 10;
    background: #D11A2D;

    .navs-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navs-box .item{
        flex: 1;
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        height: 50px;
        line-height: 50px;
    }

    .navs-box .item:hover{
        background: #c0130d;
    }
}

</style>