<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main class="page">
        <div class="layout section-select">
          <div class="select-ul clearfix">
            <div class="title fl">院校所在地：</div>
            <div class="content fl">
              <router-link :class="'item ' + (area_id == ''?'active':'')" :to="'/schools?types='+types">全部</router-link>
              <router-link :class="'item ' + (area_id == item.id?'active':'')" :to="'/schools?types='+types+'&area_id='+item.id" v-for="item in areaList">{{ item.name }}</router-link>
            </div>
          </div>

            <div class="select-ul clearfix">
                <div class="title fl">院校主管部：</div>
              <div class="content fl">
                <router-link :class="'item ' + (types == ''?'active':'')" :to="'/schools?area_id='+area_id">全部</router-link>
                <router-link :class="'item ' + (types == item.id?'active':'')" :to="'/schools?area_id='+area_id+'&types='+item.id" v-for="item in schooltype1">{{ item.name }}</router-link>
              </div>

            </div>
            <div class="select-ul clearfix">
              <div class="title fl">学历层次：</div>
              <div class="content fl">
                <router-link :class="'item ' + (types == ''?'active':'')" :to="'/schools?area_id='+area_id">全部</router-link>
                <div v-for="item in schooltype2">
                  <div v-if="item.parentId!=17 ">
                    <div v-if="item.id==17">
                      <router-link :class="'item ' + (types == item.id?'active':'')" :to="'/schools?area_id='+area_id+'&types=24,25,26,27,28,29'">{{ item.name }}</router-link>
                    </div>
                    <div v-else>
                      <router-link :class="'item ' + (types == item.id?'active':'')" :to="'/schools?area_id='+area_id+'&types='+item.id">{{ item.name }}</router-link>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="select-ul clearfix">
              <div class="title fl">院校类别：</div>
              <div class="content fl">
                <router-link :class="'item ' + (types == ''?'active':'')" :to="'/schools?area_id='+area_id">全部</router-link>
                <router-link :class="'item ' + (types == item.id?'active':'')" :to="'/schools?area_id='+area_id+'&types='+item.id" v-for="item in schooltype3">{{ item.name }}</router-link>
              </div>

            </div>



        </div>

        <div class="layout select-cont">

            <div class="item" v-for="item in dataList.data">
                <router-link :to="'/school?id='+item.id" class="img-box">
                    <img :src="item.avatar" class="img"
                         @click.prevent="handleImageClick(item.id)"
                         alt="">
                </router-link>
                <div class="cont-box">
                    <div class="title">
                        <router-link :to="'/school?id='+item.id" class="tit">{{ item.name }}</router-link>
                        <span class="address">{{item.province_name}} - {{item.city_name}}</span>
                    </div>
                    <div class="tag">
                        <el-tag type="danger" effect="plain" class="label" v-for="type in item.typesList">{{type.name}}</el-tag>
                    </div>
                    <div class="desc">{{ item.intro }}</div>
                </div>
            </div>

        </div>

        <div class="layout page-block">
            <el-pagination background @current-change="handleCurrentChange" :page-size="dataList.per_page" layout="prev, pager, next, jumper" :total="dataList.total">
            </el-pagination>
        </div>
    </main>
</template>
<script>
import Api from "@/Api.js";
export default {
    data() {
        return {
            typesList: [],
            areaList: [],
            cityList: [],
            dataList: {
                per_page: '',
                total: '',
            },
            page: 1,
            types: '',
            area_id: '',
             schooltype1:[],
             schooltype2:[],
             schooltype3:[],
        }
    },
    methods: {
      handleImageClick(id) {
        this.$router.push({ path: '/school', query: { id: id } });
      },
        //数据填充
        getInit(){
            let that = this;
            Api.School.getSchoolInit().then((res) => {
              if (res.data.code == 1) {
                that.typesList = res.data.data.types;
                that.areaList = res.data.data.province;
                that.dataList = res.data.data.dataList;
              }

              for (let i = 0; i <that.typesList.length ; i++) {
                  if (that.typesList[i].level==1){
                     that.schooltype1.push( that.typesList[i]);
                  }else if (that.typesList[i].level==2){
                    that.schooltype2.push( that.typesList[i]);
                  }else if (that.typesList[i].level==3){
                    that.schooltype3.push( that.typesList[i]);
                  }
              }
            })
        },
        //筛选列表
        getDataListInit(){
            let that = this;
            that.types = !this.$route.query.types ? '' : this.$route.query.types ;
            that.area_id = !this.$route.query.area_id ? '' : this.$route.query.area_id ;
            Api.School.getSchoolList({
                page: that.page,
                types: that.types,
                addrsc: that.area_id,
            }).then((res) => {
                if (res.data.code == 1) {
                    that.dataList = res.data.data;
                }
            })
        },

        //切换分页
        handleCurrentChange(val) {
            let that = this;
            that.page = val;
            that.getDataListInit();
        },
    },
    mounted() {
        this.getInit();
    },
    watch: {
        '$route' () {
            this.getDataListInit();
        }
    }
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
    padding-top: 20px;
    background: #f7f8fa;
    min-height: calc(100vh - 300px);
}

.section-select{
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 0 30px;
    margin-bottom: 15px;

    .select-ul{
        border-bottom: 1px solid #ddd;
        font-size: 14px;
        color: #666;
        line-height: 30px;
        display: flex;
        padding: 10px 0;
    }

    .select-ul:last-child{
        border-bottom: none;
    }

    .title{
        width: 100px;
        min-width: 100px;
    }

    .content{
        display: flex;
        flex-wrap: wrap;
    }

    .item{
        margin-right: 30px;
        text-decoration: none;
        color: #999999;
    }

    .item:hover,
    .item.active{
        color: #D11A2D;
    }
}

.select-cont{
    min-height: 380px;

    .item{
        padding: 20px;
        margin-bottom: 20px;
        display: flex;
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        overflow: hidden;
    }

    .item .img-box{
        width: 180px;
        min-width: 180px;
        height: 150px;
        background: #eeeeee;


        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .item .cont-box{
        flex: 1;
        padding-left: 20px;

        .title{
            position: relative;

            .tit{
                color: #333333;
                text-decoration: none;
                font-weight: bold;
                font-size: 18px;
            }

            .address{
                font-weight: normal;
                position: absolute;
                right: 0;
                font-size: 15px;
            }
        }

        .tag{
            margin: 15px 0;
            display: flex;

            .label{
                margin-right: 10px;
            }
        }

        .desc{
            margin-top: 10px;
            width: 100%;
            height: 55px;
            line-height: 25px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
}


// 分页
.page-block{
    width: 100%;
    text-align: center;
    padding: 20px 0;
    background: #ffffff
}

:deep(.el-pager li:not(.disabled).active){
    background: #D11A2D!important;
}

:deep(.el-pager li:not(.active):hover){
    color: #D11A2D!important;
}

</style>