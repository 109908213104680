import { render, staticRenderFns } from "./navs.vue?vue&type=template&id=e7a20496&scoped=true"
import script from "./navs.vue?vue&type=script&lang=js"
export * from "./navs.vue?vue&type=script&lang=js"
import style0 from "./navs.vue?vue&type=style&index=0&id=e7a20496&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7a20496",
  null
  
)

export default component.exports