<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main @click="handleOutsideClick" class="page">
       <div class="layout nav-box">
            <router-link to="/" class="item">首页</router-link>
            <span> > </span>
            <router-link to="/schools" class="item">校区列表</router-link>
            <span> > </span>
            <router-link to="/" class="item active">校区详情</router-link>
       </div>
       <div class="wsc-banner">
            <div class="layout content">
                <el-image class="logo" v-if="detailData.logo" :src="detailData.logo"></el-image>
                <div class="type">
                  类型：<span v-for="item in detailData.typesList">{{ item.name }}</span>
                </div>
                <div class="cont-box">
                    <div class="title">
                        <h2>{{detailData.name}}</h2>
                        <span v-if="detailData.name_en">（ {{detailData.name_en}} ）</span>
                    </div>
                    <div class="desc">{{detailData.intro}}</div>
                </div>
            </div>
       </div>
       <div class="layout img-box">
            <div class="layout-title">师资力量
            </div>

          <div class="content" >
            <ul class="subDoctor_stateList" v-for="item in imagesList.data" :key="item.id">
              <li>
                <div class="subDoctor_state_info">
                  <div class="left">
                    <div class="left_img">
                      <div class="left_img_box">
                        <img style="width: 140px" height="160px" :src="`http://admin.zhongguojiaoyunet.com/${item.avatar}`">
                      </div>
                    </div>
                    <div class="left_info">
                      <h3><a href="69928.htm">&nbsp;&nbsp;&nbsp;&nbsp;{{ item.name }}</a></h3>
                      <div class="left_info_con">
                        <p class="addr" ><i class="el-icon-office-building"></i>{{ item.Officelocation }}</p>
                        <p class="phone" hidden="hidden"><i class="el-icon-phone"></i>{{ item.phone }}</p>
                        <p class="mail" hidden="hidden"><i class="el-icon-message"></i>{{ item.Email }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <p><strong>职  称：</strong><i>{{ item.duties }}</i></p>
                    <p><strong>经  历：</strong><i>{{ item.Academicexperience }}</i></p>
                    <p><strong>系：</strong><i>{{ item.department }}</i></p>
                  </div>
                </div>
              </li>
            </ul>

          </div>
         <el-pagination
             @size-change="handleSizeChanges"
             @current-change="handleCurrentChanges"
             :current-page="imagesList.current_page"
             :page-sizes="[2, 5, 10, 20]"
             :page-size="imagesList.per_page"
             layout="total, sizes, prev, pager, next, jumper"
             :total="imagesList.total">
         </el-pagination>
        </div>
        

        <div class="layout video-box">
          <br>  <br>  <br>
            <div class="layout-title">学校视频</div>
            <div class="content">
                <div class="item slate5-shadow" v-for="(item, index) in videoData" @click="replay(item)">
                    <div class="replay-box">
                        <img class="img" :src="item.image" alt="">
                        <div class="replay-cover"></div>
                    </div> 
                    <div class="title">{{item.name}}</div>
                </div>
            </div>
          <div class="layout-title"></div>
        </div>




      <div class="layout img-box">
        <div class="layout-title">招生专业</div>
        <div class="history-score-table">
          <el-table v-if="mybRRR" :data="this.Collegedetails.data" @filter-change="handleFilterChange" style="width: 100%">
            <el-table-column prop="edu_College_detailsData" :column-key="jian" :filters="yearFilters" label="年限" :formatter="formatDate"  width="100"></el-table-column>
            <el-table-column prop="name" label="专业名称" width="100"></el-table-column>
            <el-table-column prop="scodate" label="学制" width="100"></el-table-column>
            <el-table-column prop="score" label="分数线" width="100"></el-table-column>
            <el-table-column prop="money" label="学费" width="100"></el-table-column>
            <el-table-column label="专业介绍视频">
              <template slot-scope="scope">
                <div v-if="scope.row.Provideo!=undefined">
                  <div class="video-list" >
                    <div class="video-item slate5-shadow" v-for="(item, index) in scope.row.Provideo" :key="index" @click="replay1(item)">
                      <div class="video-content flex-container">
                        <div class="thumbnail">
                          <img class="img_Provideo" :src="`http://admin.zhongguojiaoyunet.com/${item.file_pathImage}`" alt="">
                          <div class="replay-cover"></div>
                        </div>
                        <div class="details">
                          <div class="title">{{item.title}}</div>
                          <!-- 可以在这里添加更多关于视频的详细信息 -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span v-else>暂无视频</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div v-if="mybRRR" class="layout page-block">
        <el-pagination background @current-change="handleCurrentChange" :page-size="this.Collegedetails.per_page" layout="prev, pager, next, jumper" :total="this.Collegedetails.total">
        </el-pagination>
      </div>

      <div class="layout video-box">
        <br>  <br>  <br>
        <div class="layout-title">招生宣传</div>
        <div class="content">
          <div class="item slate5-shadow" v-for="(item, index) in Enrollmentpromotion" @click="replay2(item)">
            <div class="replay-box">
              <img class="img" :src="`http://admin.zhongguojiaoyunet.com/`+item.img" alt="">
              <div class="replay-cover"></div>
            </div>
            <div class="title">{{item.title}}</div>
          </div>
        </div>
        <div class="layout-title"></div>
      </div>

      <div class="layout video-box">
        <br>  <br>  <br>
        <div class="layout-title">招生简章及章程</div>
        <div class="content">
          <iframe width="1180px" height="420px" :src="detailData.studentbrochure"></iframe>
        </div>

        <div class="layout-title"></div>
      </div>

      <div class="layout video-box">
        <div class="layout">
          <div class="layout-title">院校图片</div>
          <div class="content">
            <a class="wsc-poster" style="width: 1188px;height: 800px">
              <el-carousel :interval="5000" arrow="always" style="width: 1188px;height: 800px"  class="custom-carousel">
                <el-carousel-item v-for="(item, index)  in this.imagesarrylis"  style="width: 1188px;height: 600px" :key="index">
                  <el-image :src="item"  :alt="item"></el-image>
                </el-carousel-item>
              </el-carousel>
            </a>
          </div>
        </div>
      </div>


      <div class="layout video-box">
        <div class="layout">
          <div class="layout-title">招生信息</div>
          <div class="content">
            <div v-if="admissionslist.data!=null" v-for="(item, index) in admissionslist.data" :key="index">
              <ul class="admissions-list">
                <li>
                  <a @click="hrefsor(item.id)" data-url="admission-detail1.html">
                    <div class="admissions-item">
                      <h3>{{item.title}}</h3>
                      <p>发布日期：{{item.publishtime}}</p>
                    </div>
                  </a>
                </li>
              </ul>
              <hr>
            </div>
            <el-pagination
                @size-change="handleSizeChanges2"
                @current-change="handleCurrentChanges2"
                :current-page="admissionslist.current_page"
                :page-sizes="[2, 5, 10, 20]"
                :page-size="admissionslist.per_page"
                layout="total, sizes, prev, pager, next, jumper"
                :total="admissionslist.total">
            </el-pagination>
          </div>
        </div>
      </div>


      <div class="wsc-f7f8fa  round-box">
        <div class="layout">
          <div class="layout-title">学校周边介绍</div>
          <div class="content">
            <div class="intro">
              {{ detailData.round }}
            </div>
            <div class="thumb">
              <moxInLogaode ref="moxInLogaode" class="" :schooName="this.schooName" @updateLocation="updateLocation" />
            </div>
          </div>
        </div>
      </div>

      <div>
        <!--立即报名-->
        <div>
          <a>
            <img class="poster-img" @click="this.xianshi" src="https://admin.zhongguojiaoyunet.com/uploads/20240704/7ddcad5337137c0cafa71ad427980cf9.jpg" alt="">
          </a>
        </div>
        <!-- 引入弹出框组件，并通过v-if控制显示 -->
        <signup  ref="signup"   class="popup-container"  v-if="showsignup" :visible="showsignup" @close="showsignup = false">
        </signup>
      </div>
        <div class="wsc-red-line"></div>

        <el-dialog :title="video_name" :visible.sync="dialogVisible" width="60%">
            <div v-if="video_desc" style="margin-bottom: 10px;line-height: 26px;">{{video_desc}}</div>
            <div class="content" style="width: 100%;height: 500px;">
                <video width="100%" height="100%" :src="`${video_url}`" controls></video>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关 闭</el-button>
            </span>
        </el-dialog>

    </main>
</template>
<script>
import Api from "@/Api.js";
import moxInLogaode from "@/components/views/moxInLogaode.vue";
import signup from "@/views/ModuleS/signup.vue";

export default {
  components: {moxInLogaode,signup},
    data() {
        return {
          Enrollmentpromotion: [],
          mybRRR: false,
          showsignup: false,
          schooName: null,
          id: '',
          schoolName: '',
          page: 1,
          detailData: '',
          url: '',
          imagesList: {
            per_page: 2,
            current_page: 1,
            data: []
          },
          roundsList: [],
          videoData: '',
          dialogVisible: false,
          video_name: '',
          video_desc: '',
          video_url:'',
          Collegedetails: null,
          filters: {}, // 存储当前筛选条件
          jian: "detailsData",
          // 年限筛选项
          yearFilters: [
            {text: new Date().getFullYear() - 5, value: new Date().getFullYear() - 5, checked: false},
            {text: new Date().getFullYear() - 4, value: new Date().getFullYear() - 4, checked: false},
            {text: new Date().getFullYear() - 3, value: new Date().getFullYear() - 3, checked: false},
            {text: new Date().getFullYear() - 2, value: new Date().getFullYear() - 2, checked: false},
            {text: new Date().getFullYear() - 1, value: new Date().getFullYear() - 1, checked: false},
            {text: '全部', value: 'all'},
          ],
          studentbrochure: {
            per_page: 5,
            current_page: 1,
            data: []
          },
          schoolenames: null,
          imagesarrylis:null,
          admissionslist:{
            per_page: 5,
            current_page: 1,
            data: []
          },
        }
    },
  computed: {

  },
    methods: {
    hrefsor(){
      this.$router.push("/Selectgaode");
    },
      handleSizeChanges2(val) {
        this.admissionslist.per_page=val;
        this.getbyadmissionslist();
      },
      handleCurrentChanges2(val) {
        this.admissionslist.current_page=val;
        this.getbyadmissionslist();
      },
      handleSizeChanges1(val) {
        this.studentbrochure.per_page=val;
        this.getbyStudentbrochure();
      },
      handleCurrentChanges1(val) {
        this.studentbrochure.current_page=val;
        this.getbyStudentbrochure();
      },
      handleSizeChanges(val) {
        this.imagesList.per_page=val;
        this.getbytc();
      },
      handleCurrentChanges(val) {
        this.imagesList.current_page=val;
        this.getbytc();
      },
      xianshi(){
        setTimeout(() => {
         this.showsignup=true;
        }, 1000);
      },
      handleOutsideClick(event) {
          if (this.showsignup==true){
            // 判断点击事件是否发生在弹窗组件之外
            if (!this.$refs.signup.$el.contains(event.target)) {
              this.handleClosePopup();
            }
          }
      },
      handleClosePopup() {
        this.$confirm('此操作将关闭弹窗, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '关闭!'
          });
          this.showsignup=false;
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      },
      handleFilterChange(filters) {
        this.filters = JSON.stringify(filters);
        const openBracketIndex =  this.filters.indexOf('[');
        const result = this.filters.slice(openBracketIndex + 1, openBracketIndex + 5);
        this.sunInit(result);
      },
      //切换分页
      handleCurrentChange(val) {
        let that = this;
        that.page = val;
        that.getDetail();
      },
      formatDate(row, column, cellValue) {
          return cellValue.split('-')[0];
      },
      // 点击地图后得到位置信息
      updateLocation(Addr) {
        console.log(Addr)
      },
      getbytc(){
        Api.Teacher.getBySchoolID({
          schoolID:this.schoolenames,
          per_page:this.imagesList.per_page,
          current_page:this.imagesList.current_page
        }).then(res=>{
          this.imagesList = res.data;
        })
      },
      getbyStudentbrochure(){
        Api.Studentbrochure.getByschoolname({
          schoolid:this.schoolenames,
          per_page:this.studentbrochure.per_page,
          current_page:this.studentbrochure.current_page
        }).then(res=>{
          this.studentbrochure=res.data;
        })
      },
      //数据填充
      getbyadmissionslist(){
        Api.Article.getbyname({
          source:this.schoolenames,
          current_page:this.admissionslist.current_page,
          per_page:this.admissionslist.per_page,
        }).then(res=>{
          this.admissionslist=res.data.data;
        })
      },
      getDetail(){
            let that = this;
            that.id = this.$route.query.id;
            Api.School.GetSchoolDetail({
                id: that.id,
            }).then((res) => {
              that.schoolenames=res.data.data.detail.name;
              that.imagesarrylis=res.data.data.detail.round_images.split(',').map(url => 'https://admin.zhongguojiaoyunet.com' + url);
              Api.Enrollmentpromotion.init({
                schoolID:that.schoolenames,
              }).then(resEnrollmentpromotion=>{
                that.Enrollmentpromotion=resEnrollmentpromotion.data.data.data;
              })
               this.getbytc();
               this.getbyStudentbrochure();
                if (res.data.code == 1) {
                    that.detailData = res.data.data.detail;
                    that.schooName=res.data.data.detail.round;
                    that.$refs.moxInLogaode.schooName = this.schooName;
                    that.roundsList = res.data.data.detail.roundsList;
                  Api.School.getByvid({
                    name: that.schoolenames,
                  }).then((res) => {
                    that.videoData = res.data.data.videoData.data;
                  })
                  that.getbyadmissionslist();
                  Api.Collegedetails.getAll({
                    edu_areaID: res.data.data.detail.area_id,
                    edu_schoolID:that.schoolenames
                  }).then((res) => {
                    that.Collegedetails=res.data.data;
                    for (let i = 0; i < that.Collegedetails.data.length; i++) {
                      Api.Provideo.getAll({
                        collegedetailsID: that.Collegedetails.data[i].ID
                      }).then((res) => {
                        if (null!=res.data){
                          if (undefined!=res.data.data){
                            let datas=res.data.data;
                            for (let i = 0; i <datas.length; i++) {
                              datas[i].file_path="http://admin.zhongguojiaoyunet.com"+datas[i].file_path;
                            }
                            that.Collegedetails.data[i].Provideo=datas;
                            this.mybRRR=true;
                          }
                        }
                      })
                    }
                  })
                }
            })
        },
      sunInit(data){
         Api.Collegedetails.getAll({
           edu_areaID: this.detailData.area_id,
           edu_schoolID:this.detailData.id,
           edu_College_detailsData:data,
         }).then((res) => {
           this.Collegedetails=res.data.data;
           for (let i = 0; i < this.Collegedetails.data.length; i++) {
             Api.Provideo.getAll({
               collegedetailsID: this.Collegedetails.data[i].ID
             }).then((res) => {
               if (null!=res.data){
                 if (undefined!=res.data.data){
                   this.Collegedetails.data[i].Provideo=res.data.data;
                   this.mybRRR=true;
                 }
               }
             })
           }
         })
      },
      //播放视频
      replay(item){
            this.dialogVisible = true;
            this.video_name = item.name;
            this.video_desc = item.desc;
            this.video_url  = item.url;
        },
      replay1(item){
        this.dialogVisible = true;
        this.video_name = item.file_title;
        this.video_desc = "";
        this.video_url  =item.file_path;
      },
      replay2(item){
        this.dialogVisible = true;
        this.video_name = item.name;
        this.video_desc = item.title;
        this.video_url  = "http://oss.zhongguojiaoyunet.com/"+item.file_path;
      },
      onScoreRangeChange(scores) {
        // 当分数线区间发生变化时触发的事件
        this.scoreRange = scores;
        // 在此处可以进一步处理分数区间变化，如发送请求获取对应区间内的数据等
      },
      handleClose(done) {
        this.$confirm('确认关闭预览吗?')
            .then(_ => {
              done();
            })
            .catch(_ => {});
      },
      showPreview() {
        this.dialogVisible = true;
      },
      onPlayerPlay() {
        console.log('开始播放');
      },
      onPlayerPause() {
        console.log('暂停播放');
      }
    },
  created() {

    this.getDetail();

  },
  mounted() {
    },
    watch: {
        '$route' () {
          this.getDataListInit();
        },
    }
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
    min-height: calc(100vh - 300px);
}

.date{
  font-size: 14px;
  color: #333333;
  min-width: 110px;
  text-align: right;
}

.contentul{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

//展示分数线
.history-score-table {
  margin-top: 20px;
}

/* 假设弹出框容器的类名为 .popup-container */
.popup-container {
  overflow-y: auto; /* 自动显示垂直滚动条 */
  max-height: 500px; /* 设置一个最大高度，可以根据实际需求调整 */
  width: 80%; /* 或其他适合的宽度 */
  box-sizing: border-box;

}

/* （可选）自定义滚动条样式 */
.popup-container::-webkit-scrollbar {
  width: 10px; /* 滚动条宽度 */
}

.popup-container::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条滑块颜色 */
  border-radius: 6px; /* 滚动条滑块圆角 */
}

.registration-button {
  /* 添加样式，如颜色、字体、大小、背景等 */
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition: background-color 0.3s ease-out;
  position: fixed; /* 使用固定定位 */
  top: 10px; /* 距离顶部的距离，可以根据需要调整 */
  right: 10px; /* 距离右侧的距离，可以根据需要调整 */
  z-index: 999999;
}

.nav-box{
    display: flex;
    align-items: center;

    .item{
        font-size: 14px;
        line-height: 30px;
        padding: 15px 0;
        color: #999999;
        text-decoration: none;
        margin: 0 10px;
    }

    span{
        font-size: 14px;
        color: #999999;
    }

    .item.active{
        color: #D11A2D;
    }
}

.nav-box:before{
    display: block;
    content: '';
    width: 22px;
    height: 19px;
    background-image: url(../../assets/icon/home-ico.png);
    background-repeat: no-repeat;
    background-position: left center;
}

.wsc-banner{
    width: 100%;
    background-image: url(../../assets/images/school-banner-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 30px 0;

    .logo{
        width: auto;
        height: 80px;
        margin-bottom: 20px;
        margin-left: 30px;
    }

    .content{
        padding-right: 100px;
    }

    .tag{
        margin-top: 20px;
    }
    .tag .item{
        margin-right: 10px;
    }

    .cont-box{
        min-height: 300px;
        padding: 20px 30px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 10px;
    }

    .title{
        display: flex;
        align-items: center;

        h2{
            font-size: 30px;
            font-weight: bold;
            color: #111111;
            margin: 0;
        }

        span{
            margin-left: 10px;
            font-size: 18px;
            color: #666666;
        }
    }

    .desc{
        margin-top: 20px;
        line-height: 30px;
    }

    .type{
        color: black;
        position: absolute;
        /* 以下示例值仅做示例，根据实际情况调整 */
      left:860px; /* 根据需要调整水平位置，这里设置为左对齐 */
      top: 80px;
      transform: translateY(-100%); /* 微调，如果有需要，向上微调以完美对齐 */
        span::after{
            display: inline-block;
            content: '、';
        }
        span:last-child::after{
            content: '';
        }
    }
}

.layout-title .tip{
    font-weight: normal;
    font-size: 16px;
    color: #999999;
    margin-left: 5px;
}

.img-box{
    margin-top: 30px;

    .content{
        display: flex;
        flex-wrap: wrap;
    }

    .img{
        margin: 10px;
    }
}


.round-box{
  z-index: -1;
    padding: 40px 0;
    margin-top: 30px;

    .intro{
        text-indent: 2em;
        line-height: 30px;
        margin-top: 20px;
    }

    .thumb{
        margin-top: 20px;
    }

    .img{
        margin: 10px;
    }
}

.video-box{
    margin-top: 30px;

    .content{
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: -10px;
        margin-right: -10px;
    }

    .item{
        margin: 10px;
        width: 282px;
        display: block;
        border-radius: 5px;
        overflow: hidden;
        background: #ffffff;
        text-decoration: none;
        border: 1px solid #eeeeee;
    }

    .replay-box{
        width: 282px;
        height: 170px;
        position: relative;

        .replay-cover{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.35);
            opacity: 0;
            transition: all .25s;
        }

        .replay-cover:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 55%;
            margin: -30px 0 0 -30px;
            width: 60px;
            height: 60px;
            transition: all .25s;
            background-image: url(../../assets/icon/replay.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            -webkit-transform: scale(1.5);
            transform: scale(1.5);
        }
    }

    .item:hover .replay-cover {
        opacity: 1
    }
    
    .item:hover .replay-cover:before {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    .img{
        width: 282px;
        height: 170px;
        object-fit: cover;
    }
    
    .title{
        padding: 0 10px;
        text-decoration: none;
        color: #333333;
        font-size: 16px;
        line-height: 40px;
        height: 50px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }

    .title:hover{
        color: #D11A2D;
    }

    .more{
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .refresh-btn{
        border-radius: 5px;
        transition: all .2s;
        background: #fff;
        color: #666;
        text-align: center;
        cursor: pointer;
        display: inline-block;
        width: 160px;
        height: 40px;
        border: 1px #ddd solid;
        line-height: 38px;
        background: #f7f8fa;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .refresh-btn:hover{
        background: #D11A2D;
        color: #FFFFFF;
    }

}

.wsc-red-line{
    margin-top: 40px;
    width: 100%;
    height: 6px;
    background: #D11A2D;
}

.custom-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #4a90e2;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 1rem 2rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.custom-link:hover {
  background-color: rgba(74, 144, 226, 0.1);
  color: #3b7cc9;
}
.img_01{
  width: 155px;
  height: 155px;
  background: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.figure {
  /* 基本样式 */
}

.description {
  font-size: 14px;
  color: #666;
  margin-top: 8px;
}

.name {
  font-weight: bold;
  margin-bottom: 2px;
}

// 立即报名
.wsc-poster{



}
.poster-img{
  margin: 30px 0 0;
  display: block;
  cursor: pointer;
  width: 80%;
  height: 80px
}
//师资力量

ul, ol, li {
  list-style: none outside none;
}

.subDoctor_state_info {
  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  .subDoctor_state_info .left {
    width: 100%;
    float: none;
    overflow: hidden;
    border-right: none;
  }
}

@media screen and (max-width: 1200px) {
  .subDoctor_state_info .left {
    width: 62%;
  }
}

.subDoctor_state_info .left {
  float: left;
  width: 53%;
  border-right: 1px dashed #e0e0e0;
}

.left_img {
  float: left;
  width: 114px;
}



.left_info {
  margin-left: 140px;
  margin-top: 10px;
}

.left_info h3 {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 15px;
}

@media screen and (max-width: 1000px) {
  .subDoctor_state_info .right {
    width: 100%;
    float: none;
    overflow: hidden;
  }
}
@media screen and (max-width: 1200px) {
  .subDoctor_state_info .right {
    width: 35%;
  }
}

.left_info .left_info_con p {
  padding-left: 25px;
  color: #999999;
  font-size: 15px;
  margin-bottom: 10px;
}


@media screen and (max-width: 1000px) {
  .subDoctor_state_info .right {
    width: 100%;
    float: none;
    overflow: hidden;
  }
}
@media screen and (max-width: 1200px) {
  .subDoctor_state_info .right {
    width: 35%;
  }
}

.subDoctor_state_info .right {
  float: right;
  width: 40%;
  margin-top: 20px;
}

.subDoctor_state_info .right p {
  margin-bottom: 10px;
  font-size: 15px;
  font-style: normal;
}

.subDoctor_stateList li a {

  display: block;
  overflow: hidden;
}
a {
  outline: none;
  text-decoration: none;
  color: #333;
}


.video-preview-cover {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 20px;
}

.video-player {
  width: 100%;
  height: 400px;
}
.video-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* 平均分布，两端对齐 */
}

.video-item {
  /* 添加适当的间距和尺寸 */
  margin-bottom: 20px;
  width: calc(30% - 20px); /* 三个并排时的宽度，减去margin */
}

.flex-container {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
}

.thumbnail {
  position: relative; /* 为覆盖层准备 */
  flex-shrink: 0; /* 不缩小 */
}

.img_Provideo {
  width: 50%; /* 图片自适应容器 */
}

.replay-cover {
  /* 假设这是一个覆盖在图片上的播放图标层 */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0, 0.5); /* 半透明黑色背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* 默认隐藏 */
  transition: opacity 0.3s; /* 平滑过渡 */
}

.video-item:hover .replay-cover {
  opacity: 1; /* 鼠标悬停时显示 */
}
.title h2 {
  /* 允许自动换行 */
  white-space: normal;
  /* 当单词太长时强制换行 */
  overflow-wrap: break-word;
  /* 可选：设置一个最大宽度以限制标题的宽度，超出后换行 */
  max-width: 440px; /* 或者设置为你希望的具体宽度，例如 200px */
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}


.custom-carousel .el-carousel__item img {
  width: 100%;
  height: auto;
  object-fit: cover; /* 保持图片宽高比填充容器 */
}

//招生信息的样式
.admissions-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column; /* 使列表项垂直排列 */
}

.admissions-list li {
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  transition: background-color 0.3s;
  display: flex; /* 使用Flexbox布局 */
  align-items: flex-start; /* 垂直方向上对齐开始位置 */
}

.admissions-list li:last-child {
  margin-bottom: 0; /* 最后一个列表项不设置底部外边距 */
}

.admissions-list a {
  text-decoration: none;
  color: #333;
  display: block;
  width: 100%; /* 确保链接占据整个列表项的宽度 */
  padding: 5px; /* 给链接添加一些内边距 */
}

.admissions-list .admissions-item {
  width: 100%;
  display: flex;
  align-items: center; /* 垂直居中对齐 */
}

.admissions-list .admissions-item h3 {
  margin-right: 10px; /* 在标题和发布日期之间添加一些间距 */
}
</style>